import BookingForm from './views/BookingForm.vue'
// import PanchakarmaForm from './views/PanchakarmaForm.vue'
import ConfirmationPage from './views/ConfirmationPage.vue'
import DetailsForm from './views/DetailsForm.vue'
import ThankyouDetail from './views/ThankyouDetail.vue'
export default [
    {
        path: '/retreat-reservation-form/', 
        component:BookingForm
    },
    // {
    //     path: '/panchakarma-form/', 
    //     component:PanchakarmaForm
    // },
    {
        path: '/confirmation/:userid1/:userid2', 
        component:ConfirmationPage
    },
    {
        path: '/customer/:userid/deals/:dealsid',
        component: DetailsForm,
    },
    {
        path: '/thankyou',
        component: ThankyouDetail,
    }
]