<template>
    <v-col md="8" cols="12">
        <v-row>
            <v-col cols="12" class="pb-0">
                Regular price in IDR
            </v-col>
            <v-col cols="12" class="pt-0">
                <h2>Rp {{ currency }}</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pb-0">
                Pay 50% of your Retreat in advance
            </v-col>
            <v-col cols="12" class="pt-0">
                <h1>Rp {{ payment }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pb-0">
                Participant Disclaimer
            </v-col>
            <v-col cols="12" class="pb-0">
                <a target="_blank" href="https://oneworldretreats.com/program-participants-disclaimer/">Oneworld Retreats Participants Disclaimer</a>
            </v-col>
            <v-col cols="12" class="pt-0">
                <a target="_blank" href="https://oneworldretreats.com/privacy-policy/">Oneworld Retreats Privacy Policy</a>
            </v-col>
            <v-col cols="12" class="pt-0">
                <v-checkbox
                    v-model="checkbox"
                    label="I have read and agree to the Retreat Participants Disclaimer and Privacy Policy"
                ></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pb-0">
                Payment Methods
            </v-col>
            <v-col cols="12" class="pt-0">
                <v-radio-group v-model="payload.payment" class="pt-0">
                    <v-radio
                        v-for="(method, index) in payment_methods"
                        :key="index"
                        :label="method.show"
                        :value="method.code"
                    ></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row v-if="payload.payment[0]=='else'">
            <v-col cols="12" class="pb-0">
                <h4>Please enter your question below</h4>
                <v-textarea
                    outlined
                    v-model="payload.question"
                ></v-textarea>
            </v-col>
        </v-row>
        <v-row v-else-if="payload.payment[0]=='bank'">
            <v-col cols="12" class="pb-0">
                <p>Please note this information. We will also email it to the email address you provided.</p>
                <p>Beneficiary Bank: BANK CENTRAL ASIA</p>
                <p>Beneficiary Name: PT ONE WORLD RETREAT</p>
                <p>Beneficiary Address: Jl. By Pass Ngurah Rai 101, SANUR, 80227, BALI, INDONESIA</p>
                <p>Bank account number: 0402174445</p>
                <p>Address: Jl. HASANUDIN NO. 58, DENPASAR, 80119, BALI, INDONESIA</p>
                <p>SWIFT CODE: CENAIDJA</p>

                <p>Our reservations staff will confirm your booking and will send you a copy of the invoice as soon as we receive payment.</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn 
                    :disabled="!valid"
                    block
                    depressed
                    large
                    color="primary"
                    :loading="loading"
                    @click="paymentProcess(payload)"
                >
                    Submit
                </v-btn>

                <v-snackbar
                    v-model="snackbar"
                >
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="primary"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                        Close
                        </v-btn>
                    </template>
                </v-snackbar>

                <v-dialog
                    eager
                    max-width="500"
                    v-model="dialog"
                >
                    <v-card height="600">
                        <iframe 
                            v-if="iframeReady"
                            :src="iframe"
                            frameborder="0"
                        ></iframe>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-col>
</template>
<script src="https://jokul.doku.com/jokul-checkout-js/v1/jokul-checkout-1.0.0.js"></script>
<script>
import axios from 'axios';
export default {
    name: 'PaymentShow',
    props: {
        paymentData: Object,
        valid:Boolean,
        coba:Object
    },
    data () {
        return {
            checkbox: false,
            loading: false,
            snackbar: false,
            text: '',
            dialog: false,
            iframeReady: false,
            payload: this.paymentData,
            payment_methods: [
                {
                    show: 'Credit card',
                    code: ["CREDIT_CARD"]
                },
                {
                    show: 'Bank transfer',
                    code: ['bank']
                },
                {   
                    show: 'Do you still have question or would like more information?',
                    code: ['else']
                },
            ],
        }
    },
    computed: {
        currency() {
            return new Intl.NumberFormat('en-US').format(this.paymentData.price);
        },
        payment() {
            return new Intl.NumberFormat('en-US').format(this.paymentData.price/2);
        }
    },
    methods: {
        async paymentProcess(payload) {
            try {
                this.coba.validate()   
            } catch (error) {
                this.text = 'Please fullfil the details'
                this.snackbar = true
            }
            if (this.coba.validate() != false && this.payload.paymentMethods != "" && this.checkbox == true) { 
                this.loading = true
                await axios.post('https://api.oneworldbali.com/owr/retreat-payment', payload).then(response => {
                // await axios.post('http://localhost:3344/owr/retreat-payment', payload).then(response => {
                    if (response.status == 200 && response.data.status == 'DOKU') {
                        this.iframe = response.data.link+'?view=iframe'
                        this.dialog = true
                        this.iframeReady = true
                    } else {
                        window.location.replace(response.data.link)
                    }
                }).catch(error => {
                    console.log(error.response)
                });
                this.loading= false  
            } else if (this.coba.validate() != false && this.payload.paymentMethods == "") {
                this.text = 'You do not choose yet the payment method'
                this.snackbar = true
            } else if (this.checkbox == false) {
                this.text = 'You do not tick yet the program disclaimer'
                this.snackbar = true
            }
        }
    }
}
</script>