<template>
    <v-container style="margin-top:120px; margin-bottom:120px;">
        <v-form
            v-model="valid"
            ref="form"
            lazy-validation
        >
        <v-row
            no-gutters
            justify="center"
        >
            <v-col md="8" cols="12">
                <h1 style="text-align:center;">Retreat Reservation Form</h1>
                <div class="border-information">
                    <ul>
                        <li>50% deposit is payable via VISA, Mastercard, or bank transfer and must be made within 7 working days from booking confirmation.</li>
                        <li>Oneworld Retreats does not offer refunds on your deposit, however up to 100% of your deposit may be used for another retreat within 12 months of the original Panchakarma date. Cancellation penalties may apply.</li>
                        <li>Oneworld Retreats does not offer credit for arriving late or leaving early and no partial refunds for any services not used during your Panchakarma.</li>
                        <li>Payment indicates agreement to:
                            <br>
                            <a target="_blank" href="https://oneworldretreats.com/program-participants-disclaimer/">Oneworld Retreats Participants Disclaimer</a>
                            <br>
                            <a target="_blank" href="https://oneworldretreats.com/privacy-policy/">Oneworld Retreats Privacy Policy</a>

                        </li>
                    </ul>
                </div>
                <p class="para">We recommend you book your flights ONLY AFTER you have received a confirmation from us.</p>
            </v-col>
            <v-col md="8" cols="12">
                <v-row>
                    <v-col
                        cols="12"
                    >
                    <h4>Retreat Name</h4>
                        <v-select
                            :items="productList"
                            v-model="chooseProduct"
                            item-text="name"
                            item-value="id"
                            @change="priceDetails"
                            outlined
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                    >
                    <h4>Retreat Date</h4>
                        <v-select
                            :items="priceList"
                            v-model="chooseDate"
                            item-text="date"
                            item-value="id"
                            outlined
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        v-show="payload.priceList != ''"
                    >
                    <h4>Room type</h4>
                        <v-select
                            :items="rooms"
                            v-model="payload.room"
                            item-text="name"
                            item-value="name"
                            outlined
                            @change="personMax"
                        ></v-select>
                    </v-col>
                </v-row>
                <!-- guest 1 -->
                <v-row>
                    <v-col
                        cols="12"
                    >
                        <h2 v-if="payload.max==1">Your personal information</h2>
                        <h2 v-else>First participant</h2>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                    <h4>Title</h4>
                        <v-select
                            :items="titles"
                            v-model="payload.guest1.title"
                            outlined
                            :rules="textRules"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                    <h4>First Name</h4>
                        <v-text-field
                                v-model="payload.guest1.first"
                                outlined
                                :rules="textRules"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                    <h4>Last Name</h4>
                        <v-text-field
                                v-model="payload.guest1.last"
                                outlined
                                :rules="textRules"
                        ></v-text-field>
                    </v-col>
                    <!-- list 2 -->
                    <v-col
                        cols="12"
                        sm="4"
                    >
                    <h4>Country of origin</h4>
                        <v-select
                            :items="country"
                            v-model="payload.guest1.country"
                            item-text="name"
                            outlined
                            :rules="textRules"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                    <h4>Mobile phone number</h4>
                        <v-text-field
                                v-model="payload.guest1.phone"
                                outlined
                                :rules="textRules"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                    <h4>Email address</h4>
                        <v-text-field
                                v-model="payload.guest1.email"
                                outlined
                                :rules="emailRules"
                        ></v-text-field>
                    </v-col>
                    <!-- list 3 -->
                    <v-col
                        cols="12"
                    >
                        <h4>Have you done a retreat at Oneworld before?</h4>
                        <v-radio-group row v-model="payload.guest1.pkbefore">
                            <v-radio
                                v-for="(method, index) in pkbefore"
                                :key="index"
                                :label="method"
                                :value="method"
                                :rules="textRules"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <h4>Do you have any physical disorder that we should be aware of before your arrival?</h4>
                        <v-textarea
                            outlined
                            v-model="payload.guest1.health"
                        ></v-textarea>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                        <h4>Special request</h4>
                        <v-textarea
                            outlined
                            v-model="payload.guest1.special"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        How did you hear about us
                        <v-select class="text-hidden"
                            v-model="payload.How_did_you_hear_about_us"
                            :items="hears"
                            outlined
                        ></v-select>
                    </v-col> 
                    <v-col cols="12" v-show="payload.How_did_you_hear_about_us == 'Friend'" class="text-hidden">
                        Friend's name or your other source of information
                        <v-text-field
                            v-model="payload.Source_of_info"
                            outlined
                        ></v-text-field>
                    </v-col>
                </v-row>
                <!-- guest 2 -->
                <v-row v-if="payload.max==2">
                    <v-col
                        cols="12"
                    >
                        <h2>Second participant</h2>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <h4>Title</h4>
                        <v-select
                            :items="titles"
                            v-model="payload.guest2.title"
                            outlined
                            :rules="textRules"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <h4>First Name</h4>
                        <v-text-field
                                v-model="payload.guest2.first"
                                outlined
                                :rules="textRules"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <h4>Last Name</h4>
                        <v-text-field
                                v-model="payload.guest2.last"
                                outlined
                                :rules="textRules"
                        ></v-text-field>
                    </v-col>
                    <!-- list 2 -->
                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <h4>Country of origin</h4>
                        <v-select
                            :items="country"
                            v-model="payload.guest2.country"
                            item-text="name"
                            outlined
                            :rules="textRules"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <h4>Mobile phone number</h4>
                        <v-text-field
                                v-model="payload.guest2.phone"
                                outlined
                                :rules="textRules"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <h4>Email address</h4>
                        <v-text-field
                                v-model="payload.guest2.email"
                                outlined
                                :rules="emailRules2"
                        ></v-text-field>
                    </v-col>
                    <!-- list 3 -->
                    <v-col
                        cols="12"
                    >
                        <h4>Have you done a Panchakarma before?</h4>
                        <v-radio-group row v-model="payload.guest2.pkbefore">
                            <v-radio
                                v-for="(method, index) in pkbefore"
                                :key="index"
                                :label="method"
                                :value="method"
                                :rules="textRules"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <h4>Do you have any physical disorder that we should be aware of before your arrival?</h4>
                        <v-textarea
                            outlined
                            v-model="payload.guest2.health"
                        ></v-textarea>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <h4>Special request</h4>
                        <v-textarea
                            outlined
                            v-model="payload.guest2.special"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
            <PaymentShow :valid="valid" :paymentData="payload" :coba="$refs.form" />
            <!-- <v-col cols="12" sm="4"> -->
                <!-- <PaymentShow :valid="valid" :paymentData="payload" :coba="$refs.form" /> -->
            <!-- </v-col> -->
        </v-row>
        </v-form>
    </v-container>
</template>
<script>
import PaymentShow from '../components/Booking/PaymentShow.vue'
const axios = require('axios').default;
export default {
    name: 'BookingForm',
    data() {
        return {
            payload: {
                pipeline:"Retreat",
                stage:"New Reservation - retreat",
                productList: [],
                priceList:[],
                room:"",
                price:0,
                How_did_you_hear_about_us:'',
                Source_of_info: '',
                payment:["CREDIT_CARD"],
                max: 1,
                guest1:{
                    "title":"",
                    "first": "",
                    "last": "",
                    "country":"",
                    "phone":"",
                    "email":"",
                    "pkbefore": "",
                    "special": "",
                    "health":""
                },
                guest2:{
                    "title":"",
                    "first": "",
                    "last": "",
                    "country":"",
                    "phone":"",
                    "email":"",
                    "pkbefore": "",
                    "special": "",
                    "health":""
                }
            },
            hears: [
                'Google',
                'Instagram',
                'Facebook',
                'Youtube',
                'Friend',
                'Email/newsletter'
            ],
            productList: [],
            priceList: [],
            valid: true,
            nowDate: new Date().toISOString().slice(0,10),
            rooms: [ 
                {   
                    id: 1,
                    name: 'Single occupancy'
                },
                {   
                    id: 2,
                    name: 'Double occupancy (1 bed)'
                },
                {   
                    id: 3,
                    name: 'Twin share (2 beds)'
                }
            ],
            country:[ 
                {name: 'Afghanistan', code: 'AF'}, 
                {name: 'Åland Islands', code: 'AX'}, 
                {name: 'Albania', code: 'AL'}, 
                {name: 'Algeria', code: 'DZ'}, 
                {name: 'American Samoa', code: 'AS'}, 
                {name: 'AndorrA', code: 'AD'}, 
                {name: 'Angola', code: 'AO'}, 
                {name: 'Anguilla', code: 'AI'}, 
                {name: 'Antarctica', code: 'AQ'}, 
                {name: 'Antigua and Barbuda', code: 'AG'}, 
                {name: 'Argentina', code: 'AR'}, 
                {name: 'Armenia', code: 'AM'}, 
                {name: 'Aruba', code: 'AW'}, 
                {name: 'Australia', code: 'AU'}, 
                {name: 'Austria', code: 'AT'}, 
                {name: 'Azerbaijan', code: 'AZ'}, 
                {name: 'Bahamas', code: 'BS'}, 
                {name: 'Bahrain', code: 'BH'}, 
                {name: 'Bangladesh', code: 'BD'}, 
                {name: 'Barbados', code: 'BB'}, 
                {name: 'Belarus', code: 'BY'}, 
                {name: 'Belgium', code: 'BE'}, 
                {name: 'Belize', code: 'BZ'}, 
                {name: 'Benin', code: 'BJ'}, 
                {name: 'Bermuda', code: 'BM'}, 
                {name: 'Bhutan', code: 'BT'}, 
                {name: 'Bolivia', code: 'BO'}, 
                {name: 'Bosnia and Herzegovina', code: 'BA'}, 
                {name: 'Botswana', code: 'BW'}, 
                {name: 'Bouvet Island', code: 'BV'}, 
                {name: 'Brazil', code: 'BR'}, 
                {name: 'British Indian Ocean Territory', code: 'IO'}, 
                {name: 'Brunei Darussalam', code: 'BN'}, 
                {name: 'Bulgaria', code: 'BG'}, 
                {name: 'Burkina Faso', code: 'BF'}, 
                {name: 'Burundi', code: 'BI'}, 
                {name: 'Cambodia', code: 'KH'}, 
                {name: 'Cameroon', code: 'CM'}, 
                {name: 'Canada', code: 'CA'}, 
                {name: 'Cape Verde', code: 'CV'}, 
                {name: 'Cayman Islands', code: 'KY'}, 
                {name: 'Central African Republic', code: 'CF'}, 
                {name: 'Chad', code: 'TD'}, 
                {name: 'Chile', code: 'CL'}, 
                {name: 'China', code: 'CN'}, 
                {name: 'Christmas Island', code: 'CX'}, 
                {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
                {name: 'Colombia', code: 'CO'}, 
                {name: 'Comoros', code: 'KM'}, 
                {name: 'Congo', code: 'CG'}, 
                {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
                {name: 'Cook Islands', code: 'CK'}, 
                {name: 'Costa Rica', code: 'CR'}, 
                {name: 'Cote D\'Ivoire', code: 'CI'}, 
                {name: 'Croatia', code: 'HR'}, 
                {name: 'Cuba', code: 'CU'}, 
                {name: 'Cyprus', code: 'CY'}, 
                {name: 'Czech Republic', code: 'CZ'}, 
                {name: 'Denmark', code: 'DK'}, 
                {name: 'Djibouti', code: 'DJ'}, 
                {name: 'Dominica', code: 'DM'}, 
                {name: 'Dominican Republic', code: 'DO'}, 
                {name: 'Ecuador', code: 'EC'}, 
                {name: 'Egypt', code: 'EG'}, 
                {name: 'El Salvador', code: 'SV'}, 
                {name: 'Equatorial Guinea', code: 'GQ'}, 
                {name: 'Eritrea', code: 'ER'}, 
                {name: 'Estonia', code: 'EE'}, 
                {name: 'Ethiopia', code: 'ET'}, 
                {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
                {name: 'Faroe Islands', code: 'FO'}, 
                {name: 'Fiji', code: 'FJ'}, 
                {name: 'Finland', code: 'FI'}, 
                {name: 'France', code: 'FR'}, 
                {name: 'French Guiana', code: 'GF'}, 
                {name: 'French Polynesia', code: 'PF'}, 
                {name: 'French Southern Territories', code: 'TF'}, 
                {name: 'Gabon', code: 'GA'}, 
                {name: 'Gambia', code: 'GM'}, 
                {name: 'Georgia', code: 'GE'}, 
                {name: 'Germany', code: 'DE'}, 
                {name: 'Ghana', code: 'GH'}, 
                {name: 'Gibraltar', code: 'GI'}, 
                {name: 'Greece', code: 'GR'}, 
                {name: 'Greenland', code: 'GL'}, 
                {name: 'Grenada', code: 'GD'}, 
                {name: 'Guadeloupe', code: 'GP'}, 
                {name: 'Guam', code: 'GU'}, 
                {name: 'Guatemala', code: 'GT'}, 
                {name: 'Guernsey', code: 'GG'}, 
                {name: 'Guinea', code: 'GN'}, 
                {name: 'Guinea-Bissau', code: 'GW'}, 
                {name: 'Guyana', code: 'GY'}, 
                {name: 'Haiti', code: 'HT'}, 
                {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
                {name: 'Holy See (Vatican City State)', code: 'VA'}, 
                {name: 'Honduras', code: 'HN'}, 
                {name: 'Hong Kong', code: 'HK'}, 
                {name: 'Hungary', code: 'HU'}, 
                {name: 'Iceland', code: 'IS'}, 
                {name: 'India', code: 'IN'}, 
                {name: 'Indonesia', code: 'ID'}, 
                {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
                {name: 'Iraq', code: 'IQ'}, 
                {name: 'Ireland', code: 'IE'}, 
                {name: 'Isle of Man', code: 'IM'}, 
                {name: 'Israel', code: 'IL'}, 
                {name: 'Italy', code: 'IT'}, 
                {name: 'Jamaica', code: 'JM'}, 
                {name: 'Japan', code: 'JP'}, 
                {name: 'Jersey', code: 'JE'}, 
                {name: 'Jordan', code: 'JO'}, 
                {name: 'Kazakhstan', code: 'KZ'}, 
                {name: 'Kenya', code: 'KE'}, 
                {name: 'Kiribati', code: 'KI'}, 
                {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
                {name: 'Korea, Republic of', code: 'KR'}, 
                {name: 'Kuwait', code: 'KW'}, 
                {name: 'Kyrgyzstan', code: 'KG'}, 
                {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
                {name: 'Latvia', code: 'LV'}, 
                {name: 'Lebanon', code: 'LB'}, 
                {name: 'Lesotho', code: 'LS'}, 
                {name: 'Liberia', code: 'LR'}, 
                {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
                {name: 'Liechtenstein', code: 'LI'}, 
                {name: 'Lithuania', code: 'LT'}, 
                {name: 'Luxembourg', code: 'LU'}, 
                {name: 'Macao', code: 'MO'}, 
                {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
                {name: 'Madagascar', code: 'MG'}, 
                {name: 'Malawi', code: 'MW'}, 
                {name: 'Malaysia', code: 'MY'}, 
                {name: 'Maldives', code: 'MV'}, 
                {name: 'Mali', code: 'ML'}, 
                {name: 'Malta', code: 'MT'}, 
                {name: 'Marshall Islands', code: 'MH'}, 
                {name: 'Martinique', code: 'MQ'}, 
                {name: 'Mauritania', code: 'MR'}, 
                {name: 'Mauritius', code: 'MU'}, 
                {name: 'Mayotte', code: 'YT'}, 
                {name: 'Mexico', code: 'MX'}, 
                {name: 'Micronesia, Federated States of', code: 'FM'}, 
                {name: 'Moldova, Republic of', code: 'MD'}, 
                {name: 'Monaco', code: 'MC'}, 
                {name: 'Mongolia', code: 'MN'}, 
                {name: 'Montserrat', code: 'MS'}, 
                {name: 'Morocco', code: 'MA'}, 
                {name: 'Mozambique', code: 'MZ'}, 
                {name: 'Myanmar', code: 'MM'}, 
                {name: 'Namibia', code: 'NA'}, 
                {name: 'Nauru', code: 'NR'}, 
                {name: 'Nepal', code: 'NP'}, 
                {name: 'Netherlands', code: 'NL'}, 
                {name: 'Netherlands Antilles', code: 'AN'}, 
                {name: 'New Caledonia', code: 'NC'}, 
                {name: 'New Zealand', code: 'NZ'}, 
                {name: 'Nicaragua', code: 'NI'}, 
                {name: 'Niger', code: 'NE'}, 
                {name: 'Nigeria', code: 'NG'}, 
                {name: 'Niue', code: 'NU'}, 
                {name: 'Norfolk Island', code: 'NF'}, 
                {name: 'Northern Mariana Islands', code: 'MP'}, 
                {name: 'Norway', code: 'NO'}, 
                {name: 'Oman', code: 'OM'}, 
                {name: 'Pakistan', code: 'PK'}, 
                {name: 'Palau', code: 'PW'}, 
                {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
                {name: 'Panama', code: 'PA'}, 
                {name: 'Papua New Guinea', code: 'PG'}, 
                {name: 'Paraguay', code: 'PY'}, 
                {name: 'Peru', code: 'PE'}, 
                {name: 'Philippines', code: 'PH'}, 
                {name: 'Pitcairn', code: 'PN'}, 
                {name: 'Poland', code: 'PL'}, 
                {name: 'Portugal', code: 'PT'}, 
                {name: 'Puerto Rico', code: 'PR'}, 
                {name: 'Qatar', code: 'QA'}, 
                {name: 'Reunion', code: 'RE'}, 
                {name: 'Romania', code: 'RO'}, 
                {name: 'Russian Federation', code: 'RU'}, 
                {name: 'RWANDA', code: 'RW'}, 
                {name: 'Saint Helena', code: 'SH'}, 
                {name: 'Saint Kitts and Nevis', code: 'KN'}, 
                {name: 'Saint Lucia', code: 'LC'}, 
                {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
                {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
                {name: 'Samoa', code: 'WS'}, 
                {name: 'San Marino', code: 'SM'}, 
                {name: 'Sao Tome and Principe', code: 'ST'}, 
                {name: 'Saudi Arabia', code: 'SA'}, 
                {name: 'Senegal', code: 'SN'}, 
                {name: 'Serbia and Montenegro', code: 'CS'}, 
                {name: 'Seychelles', code: 'SC'}, 
                {name: 'Sierra Leone', code: 'SL'}, 
                {name: 'Singapore', code: 'SG'}, 
                {name: 'Slovakia', code: 'SK'}, 
                {name: 'Slovenia', code: 'SI'}, 
                {name: 'Solomon Islands', code: 'SB'}, 
                {name: 'Somalia', code: 'SO'}, 
                {name: 'South Africa', code: 'ZA'}, 
                {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
                {name: 'Spain', code: 'ES'}, 
                {name: 'Sri Lanka', code: 'LK'}, 
                {name: 'Sudan', code: 'SD'}, 
                {name: 'Suriname', code: 'SR'}, 
                {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
                {name: 'Swaziland', code: 'SZ'}, 
                {name: 'Sweden', code: 'SE'}, 
                {name: 'Switzerland', code: 'CH'}, 
                {name: 'Syrian Arab Republic', code: 'SY'}, 
                {name: 'Taiwan, Province of China', code: 'TW'}, 
                {name: 'Tajikistan', code: 'TJ'}, 
                {name: 'Tanzania, United Republic of', code: 'TZ'}, 
                {name: 'Thailand', code: 'TH'}, 
                {name: 'Timor-Leste', code: 'TL'}, 
                {name: 'Togo', code: 'TG'}, 
                {name: 'Tokelau', code: 'TK'}, 
                {name: 'Tonga', code: 'TO'}, 
                {name: 'Trinidad and Tobago', code: 'TT'}, 
                {name: 'Tunisia', code: 'TN'}, 
                {name: 'Turkey', code: 'TR'}, 
                {name: 'Turkmenistan', code: 'TM'}, 
                {name: 'Turks and Caicos Islands', code: 'TC'}, 
                {name: 'Tuvalu', code: 'TV'}, 
                {name: 'Uganda', code: 'UG'}, 
                {name: 'Ukraine', code: 'UA'}, 
                {name: 'United Arab Emirates', code: 'AE'}, 
                {name: 'United Kingdom', code: 'GB'}, 
                {name: 'United States', code: 'US'}, 
                {name: 'United States Minor Outlying Islands', code: 'UM'}, 
                {name: 'Uruguay', code: 'UY'}, 
                {name: 'Uzbekistan', code: 'UZ'}, 
                {name: 'Vanuatu', code: 'VU'}, 
                {name: 'Venezuela', code: 'VE'}, 
                {name: 'Viet Nam', code: 'VN'}, 
                {name: 'Virgin Islands, British', code: 'VG'}, 
                {name: 'Virgin Islands, U.S.', code: 'VI'}, 
                {name: 'Wallis and Futuna', code: 'WF'}, 
                {name: 'Western Sahara', code: 'EH'}, 
                {name: 'Yemen', code: 'YE'}, 
                {name: 'Zambia', code: 'ZM'}, 
                {name: 'Zimbabwe', code: 'ZW'} 
            ],
            pkbefore: ["Yes", "No"],
            titles:[
                "Mr",
                "Mrs",
                "Ms",
            ],
            textRules: [
                v => !!v || 'This field is required',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            emailRules2: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                v => this.payload.guest1.email != v || 'Same like E-mail first guest'
            ],
        };
    },
    methods: {
        priceDetails(id) {
            axios.get('https://api.oneworldbali.com/owr/price-list/'+id).then(response => {
            // axios.get('http://localhost:3344/owr/price-list/'+id).then(response => {
                for (let i = 0; i < response.data.length; i++) {
                    let d = new Date(response.data[i].start).toISOString().slice(0,10);
                    if (this.nowDate < d) {
                        this.priceList.push(response.data[i])
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        },
        personMax() {
            let today=new Date();
            var cmas=new Date(this.payload.priceList.start);
            var one_day=1000*60*60*24;
            let hasil = Math.ceil((cmas.getTime()-today.getTime())/(one_day))
            console.log(hasil);
            if (this.payload.room != 'Single occupancy') {
                if (hasil >= 60) {
                    this.payload.price = this.payload.priceList.double_early
                } else {
                    this.payload.price = this.payload.priceList.double_normal
                }
                this.payload.max = 2
            } else {
                if (hasil >= 60) {
                    this.payload.price = this.payload.priceList.single_early
                } else {
                    this.payload.price = this.payload.priceList.single_normal
                }
                this.payload.max = 1
            }
        }
    },
    computed: {
        chooseProduct: {
            get() {
                return this.value
            },
            set(val) {
                var data = this.productList.find(r => r.id == val)
                this.payload.productList = data
                this.priceList = []
                this.payload.priceList = []
                this.payload.price = 0
            }
        },
        chooseDate: {
            get() {
                return this.value
            },
            set(val) {
                var data = this.priceList.find(r => r.id == val)
                this.payload.priceList = data
                let d = new Date(data.start).toISOString().slice(0,10);
                this.payload.arrival = d
                this.payload.price = 0
                this.payload.room = ""
            }
        }
    },
    mounted() {
        axios.get('https://api.oneworldbali.com/owr/product-list').then(response => {
        // axios.get('http://localhost:3344/owr/product-list').then(response => {
            this.productList = response.data
        }).catch(error => {
            console.log(error);
        })
    },
    components: {
        PaymentShow
    },
    metaInfo: {
      title: 'Booking Retreat',
      titleTemplate: '%s - Oneworld Retreat'
    }
};
</script>