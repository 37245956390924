<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      elevate-on-scroll
    >
      <div class="d-flex align-center">
        <a href="https://oneworldretreats.com/">
          <v-img
          class="shrink mr-2"
          max-height="150"
          max-width="160"
          src="./assets/logo.png"
        ></v-img>
        </a>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://oneworldretreats.com/panchakarma-detox-program/"
        target="_blank"
        text
        color="accent"
        class="hidden-sc"
      >
        <span>panchakarma</span>
      </v-btn>
      <v-btn
        href="https://oneworldretreats.com/yoga-retreats/"
        target="_blank"
        text
        color="accent"
        class="hidden-sc"
      >
        <span>yoga retreats</span>
      </v-btn>
      <v-menu
        rounded="rounded"
        offset-y
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            text
            color="accent"
            class="hidden-sc"
            v-bind="attrs"
            v-on="on"
          >
            more experiences
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="experience in experiences"
            :key="experience"
            :href="experience.link"
          >
            <v-list-item-title v-text="experience.name"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        href="https://oneworldretreats.com/program-calendar/"
        target="_blank"
        text
        color="accent"
        class="hidden-sc"
      >
        <span>calendar</span>
      </v-btn>
      <v-btn
        href="https://oneworldretreats.com/special-offer/"
        target="_blank"
        text
        color="accent"
        class="hidden-sc"
      >
        <span>special offer</span>
      </v-btn>
      <v-btn
        href="https://oneworldretreats.com/testimonials/"
        target="_blank"
        text
        color="accent"
        class="hidden-sc"
      >
        <span>testimonial</span>
      </v-btn>
      <v-menu
        rounded="rounded"
        offset-y
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            text
            color="accent"
            class="hidden-sc"
            v-bind="attrs"
            v-on="on"
          >
            about
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="about in abouts"
            :key="about"
            :href="about.link"
          >
            <v-list-item-title v-text="about.name"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        href="https://oneworldretreats.com/contact-us/"
        target="_blank"
        text
        color="accent"
        class="hidden-sc"
      >
        <span>contact us</span>
      </v-btn>
      <v-app-bar-nav-icon class="hidden-mb" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="primary"
        >
          <v-list-item href="https://oneworldretreats.com/panchakarma-detox-program/">
            <v-list-item-title>PANCHAKARMA</v-list-item-title>
          </v-list-item>

          <v-list-item href="https://oneworldretreats.com/yoga-retreats/">
            <v-list-item-title>YOGA RETREATS</v-list-item-title>
          </v-list-item>
          <v-list-item href="https://oneworldretreats.com/program-calendar/">
            <v-list-item-title>MORE EXPERIENCES</v-list-item-title>
          </v-list-item>
          <v-list-item href="https://oneworldretreats.com/program-calendar/">
            <v-list-item-title>CALENDAR</v-list-item-title>
          </v-list-item>
          <v-list-item href="https://oneworldretreats.com/special-offer/">
            <v-list-item-title>SPECIAL OFFER</v-list-item-title>
          </v-list-item>
          <v-list-item href="https://oneworldretreats.com/testimonials/">
            <v-list-item-title>TESTIMONIAL</v-list-item-title>
          </v-list-item>
          <v-list-item href="https://oneworldretreats.com/about-us/">
            <v-list-item-title>ABOUT</v-list-item-title>
          </v-list-item>
          <v-list-item href="https://oneworldretreats.com/contact-us/">
            <v-list-item-title>CONTACT US</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer
      color="primary"
    >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-img
          class="shrink footer-mb"
          max-height="150"
          max-width="200"
          src="./assets/logo-owr-light.png"
        ></v-img>
        <hr>
        <p
          class="footer-mt"
        >Jl. Suweta Br. Sambahan (<a href="https://oneworldretreats.com/contact-us/#location"><b>view map</b></a>)
        <br>
        Ubud, Bali - Indonesia
        <br>
        Email: <a href="mailto:info@oneworldretreats.com">info@oneworldretreats.com</a>
        </p>
        <hr>
        <div class="footer-mt">
          <v-btn
              icon
              color="white"
              href="https://www.instagram.com/oneworldretreats/"
            >
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
            <v-btn
              icon
              color="white"
              href="https://www.youtube.com/channel/UCdCw40vezij39l2YU42xIXw"
            >
              <v-icon>mdi-youtube</v-icon>
            </v-btn>
            <v-btn
              icon
              color="white"
              href="https://web.facebook.com/OneworldRetreats/?_rdc=1&_rdr"
            >
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn
              icon
              color="white"
              href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x2dd23d48c8a78579:0xd1720a952f483efb?source=g.page.m._"
            >
              <v-icon>mdi-google</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <p class="footer-mb">Since 2003, Oneworld Retreats has created life-changing experiences, led by in-house and international retreat leaders. Relaunched in 2022, Oneworld Retreats now offers authentic Panchakarma Detox Programs and Yoga Retreats with a touch of Ayurveda.</p>
          <hr>
          <p class="footer-mt">Visit our sister companies
            <br>
            <a href="https://www.oneworldayurveda.com/" target="_blank">Oneworld Ayurveda</a> and <a href="https://balibotanica.com/">Bali Botanica</a>.
          </p>
        </v-col>
      </v-row>
    </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      drawer: false,
      group: null,
      experiences: [
        {
          'name': 'SHORT PROGRAM',
          'link': 'https://oneworldretreats.com/a-taste-of-ayurveda/'
        },
        {
          'name': 'TREAMENT',
          'link': 'https://oneworldretreats.com/ayurvedic-treatments-and-spa/'
        }
      ],
      abouts: [
        {
          'name': 'ABOUT US',
          'link': 'https://oneworldretreats.com/about-us/'
        },
        {
          'name': 'ROOMS & FACILITIES',
          'link': 'https://oneworldretreats.com/rooms-facilites/'
        },
        {
          'name': 'FAQS',
          'link': 'https://oneworldretreats.com/frequently-asked-questions/'
        }
      ]
    }
  },
  watch: {
      group () {
        this.drawer = false
      },
    },
};
</script>

<style>
.sticky-top {
    position: sticky;
    top: 60px;
}
iframe{
  height: 100%;
  width: 100%;
}

.hidden-mb{
  display: none !important;
}

header a, header button {
  font-family: 'Open Sans', sans-serif!important;
  font-size: 13px!important;
}

.v-toolbar__content a {
  font-family: 'Open Sans', sans-serif!important;
}

.v-application{
  font-family: 'Nunito Sans', sans-serif!important;
  color: #555555!important;
}

h1 {
  font-weight: 200;
  line-height: 1.18em!important;
  color: #617262!important;
  font-size: 34px;
  margin-bottom: 25px;
}

h2 {
  font-weight: 200;
  line-height: 1.18em!important;
  color: #617262!important;
  font-size: 30px;
}

.h2-mb-10{
  margin-bottom: 16px!important;
}

h4 {
  line-height: 1.18em!important;
  color: #555555!important;
  font-family: 'Nunito Sans' !important;
}

h6 {
  font-weight: 500;
    font-family: Nunito Sans;
    font-size: 17px;
    letter-spacing: .08em;
    text-transform: uppercase;
    margin-top: 24px;
    color: #617262!important;
    margin-bottom: 8px;
}

p{
  /* font-weight: 300; */
  color: #555555;
  font-size: 17px;
  line-height: 1.6;
  font-family: 'Open Sans' !important;
}
/* font end */

.v-picker {
  width: 100%!important;
}
.v-text-field--outlined fieldset {
  border: 0!important;
  background-color: #EDEDED!important;
  border-radius: 0!important;
}

@media only screen and (max-width: 600px) {
    .sticky-top {
        position: relative;
        top: 0;
    }
    iframe {
        height: 600px;
    }
    .hidden-mb{
      display: block !important;
    }
    .hidden-sc{
      display: none !important;;
    }
}
.pb-0{
    padding-bottom: 0!important;
}
.pt-0{
    padding-top: 0!important;
}

.border-information{
  border: #768c7a 1px solid;
  padding: 20px 20px 20px 25px;
}

li, .para{
  /* font-weight: 300; */
  color: #555555;
  font-size: 17px;
  line-height: 1.6;
  font-family: 'Nunito Sans' !important;
}

.para{
  margin-top: 40px;
  text-align: center;
  padding-bottom: 64px;
}

.border-information ul li {
  padding-left: 4px;
}

.v-footer{
  padding: 40px 0px!important;
}

footer p {
  color: #ced3c5!important;
  font-size: 17px;
  line-height: 1.7em;
}

footer a {
  color: #fff!important;
  text-decoration: none;
  font-family: 'Nunito Sans' !important;
}

hr{
  border: #536354 1px solid!important;
}

.footer-mt{
  margin-top: 20px;
}

.footer-mb{
  margin-bottom: 40px;
}

</style>