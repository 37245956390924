<template>
    <v-container>
        <v-row style="margin-top: 120px;">
            <v-col class="text-center">
                <h1>Thank you</h1>
                <br>
                <div>
                    <p>You will be receiving a confirmation within 48 hours.</p>
                    <p>If you do not hear from us, please email us at <a href="mailto:reservation@oneworldretreat.com">reservation@oneworldretreat.com</a></p>
                </div>
            </v-col>
        </v-row>
        <v-row style=" margin-bottom:120px;">
            <v-col class="text-center">
                <div>
                    <p>Gratefull,</p>
                    <p>The Team at Oneworld Retreat</p>
                    <p><a href="www.oneworldretreat.com">www.oneworldretreat.com</a></p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'ThankyouDetail',
    metaInfo: {
        title: 'Thank you for submiting',
        titleTemplate: '%s - Oneworld Retreat'
    }
}
</script>

<style scoped>
h1 {
    font-size: 4rem;    
}

p {
    margin: 0!important;
}

.text-center{
    text-align: center !important;
}
</style>
