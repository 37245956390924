<template>
    <v-container>
        <v-row
            no-gutters
            justify="center"
        >
            <v-col md="8" cols="12">
                <h1 style="padding-top:120px;">Preparing for your Retreat</h1>
                <p>Soon you will begin your Retreat, how exciting!</p>
                <p>To make sure you get the best possible experience, it is very helpful for us to get as much advance information as possible about you. </p>
                <p>Find below a few questions to be answered at your earliest convenience in order to make the ideal preparation for you.</p>
                <p>Rest assure that all information will be treated confidentially.</p>
                <p style="padding-bottom: 64px;">Thank you very much for taking a few minutes to fill and send this form.</p>
            </v-col>
        </v-row>
        <v-row
            no-gutters
            justify="center"
        >
            <v-col md="8" cols="12">
                <v-form
                    v-model="valid"
                    ref="form"
                    lazy-validation
                >
                    <v-row>
                        <v-col cols="12" md="4">
                            Full Name
                            <p><b>{{guest.firstname}} {{guest.lastname}}</b></p>
                        </v-col>
                        <v-col cols="12" md="4">
                            Email
                            <p><b>{{guest.email}}</b></p>
                        </v-col>
                        <v-col cols="12" md="4">
                            Phone number
                            <p><b>{{guest.phone}}</b></p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            Date of Birth
                            <v-menu v-model="menu" offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="payload[0].Date_of_Birth"
                                        v-on="on"
                                        outlined
                                        :rules="textRules"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="picker" @click="menu = false" :max="nowDate"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6">
                            Nationality
                            <v-text-field
                                v-model="payload[0].Nationality"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            Home address
                            <v-text-field
                                v-model="payload[0].Home_address"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            City
                            <v-text-field
                                v-model="payload[0].City"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            Country
                            <p><b>{{guest.country}}</b></p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            How did you hear about us
                            <v-select
                                v-model="payload[0].How_did_you_hear_about_us"
                                :items="hears"
                                outlined
                            ></v-select>
                        </v-col> 
                    </v-row>
                    <v-row v-show="payload[0].How_did_you_hear_about_us == 'Friend'">
                        <v-col cols="12">
                            Friend's name or your other source of information
                            <v-text-field
                                v-model="payload[0].Source_of_info"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <hr style="border:#ccc 1px solid; margin-bottom:20px;">
                    <h6 class="h2-mb-10">PERSONALIZING YOUR RETREAT EXPERIENCE</h6>
                    <v-row>
                        <v-col cols="12" md="6">
                            Yoga experience
                            <v-select
                                v-model="payload[0].Yoga_experience"
                                :items="experiences"
                                outlined
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            Type of yoga
                            <v-select
                                v-model="payload[0].Type_of_yoga"
                                :items="yogas"
                                outlined
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            Practiced meditation on a regular basis
                            <v-select
                                v-model="payload[0].Practise_meditation_on_a_regular_basis"
                                :items="choosen"
                                outlined
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            Meal preference
                            <v-select
                                v-model="payload[0].Meal_preference	"
                                :items="meals"
                                outlined
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            Do you have any allergies?
                            <v-textarea
                                v-model="payload[0].Allergies"
                                outlined
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <hr style="border:#ccc 1px solid; margin-bottom:20px;">
                    <h6 class="h2-mb-10">Contact person in case of emergency</h6>
                    <v-row>
                        <v-col cols="12" md="6">
                            Name
                            <v-text-field
                                v-model="payload[0].Contact_person_name"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            Mobile phone
                            <v-text-field
                                v-model="payload[0].Contact_person_phone_number"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom:120px;">
                        <v-btn 
                            block
                            depressed
                            large
                            color="primary"
                            :loading="loading"
                            :disabled="!valid"
                            @click="sendDetails(payload)"
                        >
                            Send
                        </v-btn>
                    </v-row>
                </v-form>
            </v-col>
            <!-- <DetailsCustomer :valid="valid" :payload="payload" :form="$refs.form" /> -->
        </v-row>
    </v-container>
</template>

<script>
const axios = require('axios').default;
// import DetailsCustomer from '../components/Details/DetailsCustomer.vue';
export default {
    name: 'DetailsForm',
    data() {
        return {
            hears: [
                'Google',
                'Instagram',
                'Facebook',
                'Youtube',
                'Friend',
                'Email/newsletter'
            ],
            guest: [],
            meals: [
                "None",
                "Vegetarian",
                "Vegan",
                "Pescetarian"
            ],
            experiences: [
                "Beginner",
                "Intermediate",
                "Advance"
            ],
            yogas: [
                "Hatha",
                "Iyengar",
                "Vinyasa",
                "Hot Yoga",
                "Ashtanga"
            ],
            choosen: [
                "Yes",
                "No"
            ],
            loading: false,
            menu: false,
            valid: true,
            nowDate: new Date().toISOString().slice(0,10),
            textRules: [
                v => !!v || 'This field is required',
            ],
            payload: [
                {
                    id: this.$route.params.userid,
                    Date_of_Birth: '',
                    Nationality:'',
                    City: '',
                    Home_address: '',
                    How_did_you_hear_about_us:'',
                    Yoga_experience: '',
                    Type_of_yoga: '',
                    Practise_meditation_on_a_regular_basis: '',
                    Meal_preference: '',
                    Allergies: '',
                    Contact_person_name: '',
                    Contact_person_phone_number: ''
                }
            ]
        }
    },
    methods: {
        async sendDetails(payload) {
            try {
                this.$refs.form.validate()   
            } catch (error) {
                this.text = 'Please fullfil the details'
                this.snackbar = true
            }
            if (this.$refs.form.validate() != false) {
                this.loading = true
                await axios.post('https://api.oneworldbali.com/save-details', payload).then(response => {
                if (response.status == 200) {
                    this.loading= false 
                    this.$router.push('https://oneworldretreats.com/thank-you-pk-reservation/')
                }
                }).catch(error => {
                    console.log(error.response)
                });   
            }
        }
    },
    computed: {
        picker: {
            get() {
                return this.value;
            },
            set(val) {
                this.menu = false;
                this.payload[0].Date_of_Birth = val
            }
        }
    },
    mounted() {
        axios.post('https://api.oneworldbali.com/users', {
            'userid': this.$route.params.userid
        }).then(response => {
            this.guest = response.data[0]
        }).catch(error => {
            console.log(error);
        })
    },
    metaInfo: {
      title: 'Preparing for your Panchakarma',
      titleTemplate: '%s - Oneworld Retreat'
    }
}
</script>